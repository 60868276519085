import { For, Switch, Match, Show } from "solid-js";
import { type RouteSectionProps, A } from "@solidjs/router";
import { useCheckoutContext } from "~/utils/contexts";
import {
  ProductSummary,
  CheckoutOrderSummary,
} from "~/components/ordering/checkout";
import { facilityMapping } from "~/components/ordering/order-page";
import type { SubmittedCartDetail } from "~/services/roma-api/cart/types";
import { PlantCodeKeys } from "~/services/roma-api/products/types";

export default function OrderStatus(props: RouteSectionProps) {
  const { cart: _cart } = useCheckoutContext();
  // Type casting the cart as SubmittedCartDetail - not great, but there are still runtime 
  // checks on the specific properties a submitted cart has (ShipDetail, BillTo, Orders, etc..)
  const cart = () => _cart() as unknown as SubmittedCartDetail;

  return (
    <Show when={cart()}>
      <div class="pb-4 sm:pb-20 px-4 col-span-full">
        <div class="max-w-3xl mx-auto py-10 border-b">
          <p class="hidden print:block text-lg font-bold mb-6 border-b">Order Confirmation</p>
          <div class="flex max-sm:flex-col child:basis-1/3 gap-6">
            <div>
              <p class="font-medium pb-2">Ship To</p>
              <Show when={cart()?.Pickup === true}>
                <div class="text-roma-medium-grey font-light text-sm">
                  <p>
                    Pickup from{" "}
                    {cart()?.DefaultPlant
                      ? facilityMapping[cart()?.DefaultPlant as PlantCodeKeys] +
                        " "
                      : ""}
                    warehouse
                  </p>
                </div>
              </Show>
              <Show when={cart()?.ShipDetail}>
                <div class="text-roma-medium-grey font-light text-sm">
                  <p>{cart()?.ShipDetail.Recipient}</p>
                  <p>
                    {cart()?.ShipDetail.Number} {cart()?.ShipDetail.Street}
                  </p>
                  <Show when={cart()?.ShipDetail.Street2}>
                    <p>{cart()?.ShipDetail.Street2}</p>
                  </Show>
                  <p>
                    {cart()?.ShipDetail.City}, {cart()?.ShipDetail.Region},{" "}
                    {cart()?.ShipDetail.PostalCode}
                  </p>
                  <p>{cart()?.ShipDetail.Country}</p>
                </div>
              </Show>
            </div>
            <div>
              <p class="font-medium pb-2">Bill To</p>
              <Show when={cart()?.BillTo}>
                <div class="text-roma-medium-grey font-light text-sm">
                  {/* <p>{cart()?.BillTo.Recipient}</p> */}
                  <p>
                    {cart()?.BillTo.Number} {cart()?.BillTo.Street}
                  </p>
                  <Show when={cart()?.BillTo.Street2}>
                    <p>{cart()?.BillTo.Street2}</p>
                  </Show>
                  <p>
                    {cart()?.BillTo.City}, {cart()?.BillTo.Region},{" "}
                    {cart()?.BillTo.PostalCode}
                  </p>
                  <p>{cart()?.BillTo.Country}</p>
                </div>
              </Show>
            </div>
            <Show when={cart()?.ProcessDate}>
              <div>
                <p class="font-medium pb-2">Ship After</p>
                <p class="text-roma-medium-grey font-light text-sm">
                  {cart()?.ProcessDate}
                </p>
              </div>
            </Show>
            <div>
              <p class="font-medium pb-2">Notes</p>
              <div class="text-roma-medium-grey font-light text-sm">
                <Show
                  when={cart()?.ExternalNotes !== ""}
                  fallback={<p>No notes included</p>}
                >
                  <p>{cart()?.ExternalNotes}</p>
                </Show>
              </div>
            </div>
          </div>
        </div>
        <main class="max-w-3xl mx-auto">
          <div>
            <Switch>
              <Match when={cart()?.Status == "submitted"}>
                <For each={cart().Orders}>
                  {(order) => (
                    <>
                      <div class="pt-6 pb-2">
                        <p class="text-lg">
                          {order.Type} ({order.LineCount})
                        </p>
                        <p class="text-sm font-light text-roma-medium-grey">
                          Order #{order.ID}
                        </p>
                      </div>
                      <For each={order.Lines}>
                        {(line) => <ProductSummary {...line} line={line} />}
                      </For>
                    </>
                  )}
                </For>
              </Match>
              <Match when={cart()?.Status == "active"}>
                <div class="w-full bg-white py-8">
                  <p>Selected cart has not been checked out.</p>
                  <A class="text-roma-blue" href={`/checkout/${props.params.cart_id}`}>Back to cart</A>
                </div>
              </Match>
            </Switch>
            <div class="flex sm:justify-end border-b pt-10">
              <div class="w-full sm:w-1/2 ml-auto pb-8 ">
                <CheckoutOrderSummary hideButton={true} />
              </div>
            </div>
            <div class="font-light text-roma-dark-grey py-8 text-center leading-7">
              <p>
                Notice a mistake? Please call our customer care team as soon as
                possible at{" "}
                <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
                  1 800 263 2322
                </a>{" "}
                so that we can correct your order before it ships.
              </p>
            </div>
          </div>
        </main>
      </div>
    </Show>
  );
}
